<template>
  <v-row id="common-data-table">
    <v-col cols="12">
      <base-card elevation="5">
        <slot name="topsection"></slot>
        <v-card-title class="pb-0">
          <v-row class="ma-0" align="center" v-if="modelData.headerActions && modelData.headerActions.length">
            <slot name="buttonSection"></slot>
            <v-col class="pa-0 d-flex align-center justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ma-2" :to="modelData.createLink" v-bind="attrs" v-on="on" dark color="primary"
                         v-if="modelData.headerActions.includes('create')">
                    <v-icon>mdi-plus</v-icon>
                    Create
                  </v-btn>
                </template>
                <span>Create {{ modelData.title.substring(0, modelData.title.length - 1) }}</span>
              </v-tooltip>
              <v-checkbox class="ma-2" v-model="activeChallenges" label="Active Challenges" hide-details
                          v-if="modelData.headerActions.includes('activeChallenges')"
                          @change="toggleActiveChallenges">
              </v-checkbox>
              <v-btn class="ma-2" @click="toggleExpand()" dark :color="expandedAll ? 'secondary' : 'primary'"
                     v-if="modelData.headerActions.includes('expand') && listData.length">
                <v-icon dark small class="mr-1">
                  {{ expandedAll ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}
                </v-icon>
                {{ expandedAll ? 'Collapse' : 'Expand' }} All Row
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ma-2" @click="refreshPage()" fab dark small v-bind="attrs" v-on="on" color="info"
                         v-if="modelData.headerActions.includes('reset')">
                    <v-icon dark>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Reset</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="exportLoading" class="ma-2 font-weight-bold" @click="$emit('export')" dark
                         v-bind="attrs" v-on="on" color="danger" v-if="modelData.headerActions.includes('export')">
                    Export
                    <v-icon dark class="pl-1">
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>File Download</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title class="font-weight-bold" v-if="!modelData.searchDisable || modelData.title">
          {{ modelData.title }}
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field autofocus v-if="!modelData.searchDisable" class="font-weight-regular" v-model.trim="searchDT"
                        append-icon="mdi-magnify" label="Search" single-line :disabled="loading" @input="searchDatatable"
                        hide-details></v-text-field>
        </v-card-title>
        <v-skeleton-loader v-if="loading || searchLoading" :loading="loading || searchLoading"
                           type="table-tbody, table-tfoot"></v-skeleton-loader>
        <div>
          <v-overlay :value="interSectLoading" :absolute="true">
            <v-row class="ma-0" justify="center" align="center">
              <v-progress-circular indeterminate size="30" class="mr-3"></v-progress-circular>
              <span class="font-weight-bold text-subtitle-1">Loading</span>
            </v-row>
          </v-overlay>
          <v-data-table v-if="!loading && !searchLoading"
                        :id="modelData.interSectScroll ? modelData.interSectId : 'custom-data-table'"
                        :class="modelData.interSectScroll ? modelData.interSectClass : ''"
                        v-scroll:[`#${modelData.interSectId}`]="onIntersectScroll" v-model="selected" :search="searchDT"
                        :expanded.sync="expanded" :show-expand="modelData.expandable" :headers="modelData.headers" :items="listData"
                        item-key="id" :hide-default-footer="modelData.footerHide" class="elevation-1 table-one"
                        :footer-props="footerOptions" :options.sync="options" @update:sort-by="sortableDT"
                        @update:sort-desc="sortableDT" :server-items-length="meta ? meta.total : 0">
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-btn :ref="item.id" small :color="isExpanded ? 'secondary' : 'primary'" dark icon
                     :loading="item.expandLoading" @click="handleExpansion(item, isExpanded)">
                <v-icon small>{{ isExpanded ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline' }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`expanded-item`]="{ headers, item }">
              <td :colspan="headers.length">
                <v-card class="pa-0 ma-5 mx-16" elevation="1" v-if="!item.expandLoading">
                  <v-data-table :headers="modelData.expandHeaders" :items="item.expandItem">
                    <template v-slot:[`item.datefield`]="{ item, header }">
                      {{ item[header.field] ? $helpers.getMomentDatas('YYYY-MM-DD hh:mm A', item[header.field]) : '' }}
                    </template>
                  </v-data-table>
                </v-card>
                <div v-if="item.expandLoading" class="pa-3 text-h6 secondary--text d-flex align-center justify-center">
                  Loading...
                </div>
              </td>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="activeLoading" color="primary" dark v-bind="attrs" v-on="on" icon
                         @click="$emit('activeHandled', item.id)">
                    <v-icon>
                      {{ item[activeFieldName] === 1 ? modelData.activeIcon : `${modelData.activeIcon}-outline` }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Active {{ modelData.title.substring(0, modelData.title.length - 1) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.action`]="{ item, index }">
              <div class="d-flex">
                <template v-if="modelData.customButtonActions && modelData.customButtonActions.length">
                  <v-tooltip :disabled="!customItem.tooltipName" top attach
                             v-for="(customItem, customIndex) in modelData.customButtonActions" :key="customIndex">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :color="`${customItem.color || 'info'} ${customActionDisable ? 'lighten-3' : ''}`" dark
                             v-bind="attrs" v-on="on" :loading="item.customLoading"
                             :class="[customItem.btnClass, { 'disable-events': customActionDisable }]"
                             :icon="customItem.btnIcon || false" :small="customItem.btnSmall || false"
                             @click="$emit('customAction', { button: customItem.name ? customItem.name : customItem.icon, item: item })">
                        <v-icon v-if="customItem.icon" class="mr-2">{{ customItem.icon }}</v-icon>
                        <template v-if="customItem.name">{{ customItem.name }}</template>
                      </v-btn>
                    </template>
                    <span>{{ customItem.tooltipName }}</span>
                  </v-tooltip>
                </template>
                <v-tooltip top v-if="modelData.actions && modelData.actions.includes('edit') && !item.editable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" v-on="on" @click="editItem({ item, index })" icon>
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip top v-if="modelData.actions && inlineEdit && item.editable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="secondary" dark v-bind="attrs" :disabled="item.editItemLoading" v-on="on"
                           @click="$emit('editItem', { item, index })" icon>
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top
                           v-if="modelData.actions && modelData.actions.includes('delete') && (!modelData.deleteActionHide || item[modelData.deleteActionHide.name] !== modelData.deleteActionHide.value)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="danger" dark v-bind="attrs" v-on="on" icon @click="$emit('deleteItem', item)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.datefield`]="{ item, header }">
              <v-row class="ma-0" v-if="!header.format || (header.format && header.format === 'date')">
                {{ item[header.field] ? $helpers.getMomentDatas('YYYY-MM-DD', item[header.field]) : '' }}
              </v-row>
              <v-row class="ma-0" v-if="!header.format || (header.format && header.format === 'time')">
                {{ item[header.field] ? $helpers.getMomentDatas('hh:mm A', item[header.field]) : '' }}
              </v-row>
            </template>
            <template v-slot:[`item.boolean`]="{ item, header }">
              {{ item[header.field] ? header.trueValue : header.falseValue }}
            </template>
            <template v-slot:[`item.htmlelement`]="{ item, header }">
              <template v-if="!header.link">
                <div v-html="item[header.field]"></div>
              </template>
              <template v-else>
                <a target="_blank" v-if="header.field.includes('.') && item[header.field.split('.')[0]]"
                   :href="`${header.link}/${item[header.field.split('.')[0]][header.field.split('.')[1]]}`">{{
                    item[header.field.split('.')[0]][header.field.split('.')[1]]
                  }}</a>
                <a v-if="!header.field.includes('.') && item[header.field]" target="_blank"
                   :href="`${header.link}/${item[header.field]}`">{{ item[header.field] }}</a>
              </template>
            </template>
            <template v-slot:[`item.chip`]="{ item, header }">
              <template v-if="Array.isArray(item[header.field])">
                <div class="ma-3">
                  <template v-for="(item, i) in item[header.field]">
                    <v-chip v-if="i <= 4" :key="i" small color="info" class="mr-2 my-1" dark>
                      {{ header.subfield ? item[header.subfield] : item }}
                    </v-chip>
                  </template>
                  <template v-if="item.showMore">
                    <template v-for="(item, i) in item[header.field]">
                      <v-chip v-if="i > 4" :key="i" small color="info" class="mr-2 my-1" dark>
                        {{ header.subfield ? item[header.subfield] : item }}
                      </v-chip>
                    </template>
                  </template>
                  <span v-if="(item[header.field]).length > 5" class="grey--text text-caption">
                    <span v-if="!item.showMore">(+{{ (item[header.field]).length - 5 }} others)</span>
                    <a class="info--text ml-2 font-weight-bold" @click="showMoreLess(item)">{{
                        item.showMore ? 'view less'
                          : 'view more'
                      }}</a>
                  </span>
                </div>
              </template>
              <template v-if="typeof item[header.field] == 'string'">
                <v-chip color="info" dark>
                  {{ item[header.field] }}
                </v-chip>
              </template>
            </template>
            <template v-slot:[`item.row_id`]="{ item, header }">
              <div class="my-2" v-if="item[header.field] && item[header.field].length">{{ item[header.field].join(', ') }}
              </div>
            </template>
            <template v-slot:[`item.customfield`]="{ item, header }">
              <template v-if="header.valtype == 'bool'">
                {{
                  header.valueList.findIndex(valItem => valItem.value == item[header.field]) !== -1 ?
                    (header.valueList.find(valItem => valItem.value == item[header.field])).status : ''
                }}
              </template>
              <template v-else>{{ item[header.field] }}</template>
            </template>
            <template v-slot:[`item.amount`]="{ item, header }">
              {{ header.field ? $filters.currencyComma(item[header.field]) : $filters.currencyComma(item.amount) }}
            </template>
            <template v-slot:[`item.label`]="{ item, header }">
              <v-chip class="ma-2 text-lowercase" :color="header.color" small
                      :outlined="!item[header.field] || (!header.true && !header.false)"
                      v-if="header.true || header.false || item[header.field]">
                {{ header.true && header.false ? (item[header.field] ? header.true : header.false) : item[header.field] }}
              </v-chip>
            </template>
            <template v-slot:[`item.inline`]="{ item, header }">
              <v-row v-if="!item.editable" align="center">
                <template v-if="!header.link">
                  <div v-html="item[header.field]"></div>
                </template>
                <template v-else>
                  <a target="_blank" v-if="header.field.includes('.') && item[header.field.split('.')[0]]"
                     :href="`${header.link}/${item[header.field.split('.')[0]][header.field.split('.')[1]]}`">{{
                      item[header.field.split('.')[0]][header.field.split('.')[1]]
                    }}</a>
                  <a v-if="!header.field.includes('.') && item[header.field]" target="_blank"
                     :href="`${header.link}/${item[header.field]}`">{{ item[header.field] }}</a>
                </template>
              </v-row>
              <v-row v-else>
                <v-text-field class="font-weight-regular" v-model.trim="item[header.field]" dense solo single-line
                              hide-details></v-text-field>
              </v-row>
            </template>
            <template v-slot:[`item.toggle`]="{ item, header, index }">
              <v-btn v-if="header.type=='star'" icon color="primary" v-model="item[header.field]" @click="$emit('toggleField', { item, index })"
                     :loading="!!item.toggleLoading" :disabled="item.toggleLoading">
                <v-icon>
                  {{ (item[header.field] == 0) ? 'mdi mdi-star-outline' : 'mdi mdi-star' }}
                </v-icon>
              </v-btn>
              <v-switch
                v-else dense label="" color="info" hide-details @change="$emit('toggleField', { item, index , header })"
                v-model="item[header.field]" :false-value="0" :true-value="1" :loading="item.toggleLoading"
                :disabled="item.toggleLoading || (header.field==='google2fa_status'  && !item.google2fa_status)"></v-switch>
            </template>
          </v-data-table>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';

export default {
  name: "Datatable",
  props: ["modelData", "combineData", "search", "activeFieldName", "customActionDisable", "searchModel", "dtOptions",
    "footerOptions", "inlineEdit", "sortable"],
  emits: ["deleteItem", "clearFilter", "customAction", "activeHandled", "metaResp", 'export', 'interSectLoad', 'starAction'],
  data() {
    return {
      selected: [],
      activeChallenges: false,
      page: 1,
      options: {},
      expanded: [],
      apiCalled: false,
      listData: [],
      loading: false,
      meta: null,
      dtLists: [],
      expandedAll: false,
      interSectDataEmpty: false,
      exportLoading: false,
      interSectLoading: false,
      searchTimeId: null,
      filterData: null,
      searchLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      activeLoading: "itemActiveLoading"
    }),
    searchDT: {
      get() {
        return this.$store.getters["searchDT"];
      },
      set(newValue) {
        return this.$store.commit("setSearchDT", newValue);
      },
    },
  },
  created() {
    this.searchDT = '';
    if (this.modelData.interSectScroll) {
      this.refreshPage();
    }
    this.filterData = this.searchModel;
    this.options = Object.assign({}, this.options, this.dtOptions);
  },
  methods: {
    ...mapActions(["pageNavigation", "getExpandItem"]),

    handleDTChange(type = null) {
      this.apiCalled = true;
      const { itemsPerPage, page, sortBy, sortDesc } = this.options,
        pageLink = new URL(this.modelData.pageLink);

      pageLink.searchParams.set("page", page);
      pageLink.searchParams.set("per_page", itemsPerPage);

      if (type && this.searchDT !== '') {
        pageLink.searchParams.set("page", 1);
        this.options.page = 1;
        pageLink.searchParams.set("q", this.searchDT);
      } else this.searchDT = '';

      if (sortBy.length === 1 && sortDesc.length === 1) {
        const sortModel = {
          order: sortBy[0],
          dir: !sortDesc[0] ? 'ASC' : 'DESC'
        };
        Object.keys(sortModel).map(key => sortModel[key] && pageLink.searchParams.set(key, sortModel[key]));
      }

      if (this.filterData) {
        Object.keys(this.filterData).map(key => this.filterData[key] && pageLink.searchParams.set(key, this.filterData[key]));
      }

      this.pageLoading(pageLink.toString(), null, type);
    },
    searchDatatable() {
      if (this.searchTimeId) {
        clearTimeout(this.searchTimeId);
        this.searchTimeId = false;
      }
      const self = this;
      this.searchTimeId = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        self.handleDTChange('search');
        self.searchTimeId = false;
      }, 700);
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item),
        dtIndex = this.dtLists.findIndex(data => data.id === item.id);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
      if (!state && this.modelData.expandDatas) {
        const reqData = {
          link: this.modelData.expandDatas.link,
          data: null,
          method: this.modelData.expandDatas.method || 'post',
        }
        const paramsModel = {};
        const paramsName = this.modelData.expandDatas.passField ? this.modelData.expandDatas.passField : this.modelData.expandDatas.field;
        paramsModel[paramsName] = item[this.modelData.expandDatas.field];
        if (reqData.method == 'get') {
          paramsModel['per_page'] = -1;
          reqData['link'] = this.$helpers.setURLparams(paramsModel, this.modelData.expandDatas.link);
        } else reqData['data'] = paramsModel;
        this.dtLists[dtIndex].expandLoading = true;
        let changeModel = this.dtLists[dtIndex];
        this.getExpandItem(reqData).then((resp) => {
          changeModel.expandItem = resp.data;
          changeModel.expandLoading = false;
          this.dtLists.splice(dtIndex, 1, changeModel);
        }).catch(err => {
          changeModel.expandLoading = false;
          this.dtLists.splice(dtIndex, 1, changeModel);
        })
      }
    },
    toggleExpand() {
      this.expandedAll = !this.expandedAll;
      Object.keys(this.$refs).forEach(k => {
        if(this.$refs[k] !== undefined){
          this.$refs[k].$el.click()
        }
      })
    },
    refreshPage() {
      this.apiCalled = true;
      this.options.page = 1;
      this.searchDT = '';
      this.filterData = null;
      this.pageLoading(this.modelData.pageLink);
      this.$emit('clearFilter');
    },
    pageLoading(link, reset = false, type = null) {
      this[type ? 'searchLoading' : 'loading'] = true;
      if (reset) {
        this.apiCalled = true;
        this.options.page = 1;
      }
      this.pageNavigation(link).then((data) => {
        this.meta = data.meta;
        this.$emit('metaResp', data.meta);
        this.dtLists = data.data || [];
        this.apiCalled = this[type ? 'searchLoading' : 'loading'] = false;
      }).catch(err => this.apiCalled = this[type ? 'searchLoading' : 'loading'] = false);
    },
    sortableDT() {
      if (this.sortable) {
        this.handleDTChange();
        return;
      }
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      let items = this.dtLists;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy[0]]
          const sortB = b[sortBy[0]]

          if (sortDesc[0]) {
            if (sortA < sortB) return 1
            if (sortA > sortB) return -1
            return 0
          } else {
            if (sortA < sortB) return -1
            if (sortA > sortB) return 1
            return 0
          }
        })
        if (itemsPerPage > 0 && !this.modelData.interSectScroll) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        }
        setTimeout(() => {
          this.dtLists = items || [];
        }, 200)
      }
    },
    editItem({ item, index }) {
      if (this.inlineEdit) {
        item.editable = true;
        this.dtLists.splice(index, 1, item);
        return;
      }
      if (this.modelData.customEditItem && item[this.modelData.customEditItem.field] &&
        item[this.modelData.customEditItem.field] === this.modelData.customEditItem.value) {
        return this.$router.push(this.modelData.customEditItem.link);
      }
      return this.$router.push(this.modelData.editLink + item.id);
    },
    onIntersectScroll(evt) {
      if (this.modelData.interSectScroll && evt.target.getAttribute('id') === this.modelData.interSectId && !this.interSectDataEmpty &&
        Math.ceil(evt.target.scrollTop + evt.target.clientHeight) >= (evt.target.scrollHeight - 1)) {
        this.page++;
        const pageLink = new URL(this.modelData.pageLink);
        pageLink.searchParams.set("page", this.page);
        if (this.searchDT !== '') {
          pageLink.searchParams.set("q", this.searchDT);
        }
        this.$emit('interSectLoad', true);
        this.pageNavigation(pageLink.toString()).then((data) => {
          this.meta = data.meta;
          this.$emit('metaResp', data.meta);
          data.data.forEach(item => this.dtLists.push(item));
          this.$emit('interSectLoad', false);
          if (data.meta.last_page <= this.page || data.data.length === 0) this.interSectDataEmpty = true;
        }).catch(err => this.$emit('interSectLoad', false));
      }
    },
    intersectSearch(search) {
      this.loading = true;
      this.page = 1;
      const pageLink = new URL(this.modelData.pageLink);
      pageLink.searchParams.set("page", this.page);
      pageLink.searchParams.set("q", search);
      this.pageNavigation(pageLink.toString()).then((data) => {
        this.meta = data.meta;
        this.$emit('metaResp', data.meta);
        this.dtLists = data.data || [];
        this.loading = this.interSectDataEmpty = false;
      }).catch(err => this.loading = false);
    },
    showMoreLess(item) {
      const itemIndex = this.dtLists.findIndex(dtItem => dtItem.id === item.id);
      if (itemIndex !== -1) {
        const itemData = this.dtLists[itemIndex];
        itemData.showMore = !itemData.showMore;
        this.dtLists.splice(itemIndex, 1, itemData);
      }
    },
    toggleActiveChallenges(checked) {
      const pageLink = new URL(this.modelData.pageLink);

      if(checked) {
        pageLink.searchParams.delete("with_trashed");
      } else {
        pageLink.searchParams.set("with_trashed", 1);
      }

      this.modelData.pageLink = pageLink.toString();

      this.refreshPage();
    },
  },
  watch: {
    'options.page': function () {
      if (!this.apiCalled && !this.modelData.interSectScroll) {
        this.handleDTChange();
      }
    },
    'options.itemsPerPage': function () {
      if (!this.apiCalled && !this.modelData.interSectScroll) {
        this.handleDTChange();
      }
    },
    dtLists: function (newValue) {
      this.listData = newValue;
      if (newValue.length && !_.isEmpty(this.combineData)) {
        newValue.map(async (item) => {
          const combineItem = [];
          await this.combineData.value.map((data, i) => {
            const updateDate = this.combineData.dateFormat ? this.$helpers.getMomentDatas(this.combineData.format ? this.combineData.format : 'YYYY-MM-DD hh:mm A', item[data]) : item[data]
            combineItem.push(updateDate);
            if (i === this.combineData.value.length - 1) {
              item.combine = combineItem.join(this.combineData.join);
              return item;
            }
          })
        })
        this.listData = newValue;
      }
    },
    search: function (search) {
      this.searchDT = search;
      if (this.modelData.interSectScroll) {
        this.intersectSearch(search);
      } else {
        this.searchDatatable();
      }
    },
    searchModel(item) {
      this.filterData = item;
    }
  }
}
</script>
